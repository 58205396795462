// Navigation 1
let navButton = document.querySelector(".nav__button");
let nav = document.querySelector(".nav");
let navItems = document.querySelectorAll(".nav .nav__item a");

function closeNav() {
  navButton.classList.remove("nav__button--open");
  nav.classList.remove("nav--open");
}

navButton.addEventListener("click", (event) => {
  event.stopPropagation(); // Verhindert das Auslösen des document click-Event-Listeners
  navButton.classList.toggle("nav__button--open");
  nav.classList.toggle("nav--open");
});

navItems.forEach((item) => {
  item.addEventListener("click", function(event) {
    const targetElement = document.querySelector(event.target.getAttribute('href'));
    if (targetElement) { // Prüfen, ob das Ziel-Element existiert
      event.preventDefault();
      closeNav();
      targetElement.scrollIntoView({behavior: "smooth"});
    }
  });
});

document.addEventListener("click", (event) => {
  // Überprüft, ob weder das navButton-Element noch das nav-Element den Zielknoten des click-Events enthält
  if (!navButton.contains(event.target) && !nav.contains(event.target)) {
    closeNav();
  }
});





// Slideshows Header
var swiper = new Swiper ('.header_slider', {
  loop: true,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  speed: 500,

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

var swiper_records = new Swiper ('.record_slider', {
  loop: false,
  speed: 500,
  spaceBetween: 50,
  slidesPerView: "auto",
});

var swiper_nav = new Swiper ('.nav_slider', {
  loop: false,
  speed: 500,
  spaceBetween: 50,
  slidesPerView: "auto",

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

// Consent

window.addEventListener('load', function(){

  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
      current_lang: 'de',
      autoclear_cookies: true,                   // default: false
      page_scripts: true,                        // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: '',                      // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: true,                   // default: true
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      // revision: 0,                            // default: 0

      onFirstAction: function(user_preferences, cookie){
          // callback triggered only once on the first accept/reject action
      },

      onAccept: function (cookie) {
          // callback triggered on the first accept/reject action, and after each page load
      },

      onChange: function (cookie, changed_categories) {
          // callback triggered when user changes preferences after consent has already been given
      },

      languages: {
          'de': {
              consent_modal: {
                  title: 'Wir verwenden Cookies!',
                  description: 'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt werden.<button type="button" data-cc="c-settings" class="cc-link">Lass mich wählen</button>',
                  primary_btn: {
                      text: 'Alle akzeptieren',
                      role: 'accept_all'              // 'accept_selected' or 'accept_all'
                  },
                  secondary_btn: {
                      text: 'Alle ablehnen',
                      role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                  }
              },
              settings_modal: {
                  title: 'Cookie-Einstellungen',
                  save_settings_btn: 'Einstellungen speichern',
                  accept_all_btn: 'Alle akzeptieren',
                  reject_all_btn: 'Alle ablehnen',
                  close_btn_label: 'Close',
                  // cookie_table_caption: 'Cookie list',
                  cookie_table_headers: [
                      {col1: 'Name'},
                      {col2: 'Domain'},
                      {col3: 'Expiration'},
                      {col4: 'Description'}
                  ],
                  blocks: [
                      {
                          title: 'Verwendung von Cookies 📢',
                          description: 'Ich verwende Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/datenschutz" class="cc-link">Datenschutzerklärung</a>.'
                      }, {
                          title: 'Streng notwendige Cookies',
                          description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
                          toggle: {
                              value: 'necessary',
                              enabled: true,
                              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                          }
                      }, {
                          title: 'Leistungs- und Analyse-Cookies',
                          description: 'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
                          toggle: {
                              value: 'analytics',     // your cookie category
                              enabled: false,
                              readonly: false
                          },
                          cookie_table: [             // list of all expected cookies
                              {
                                  col1: '^_ga',       // match all cookies starting with "_ga"
                                  col2: 'google.com',
                                  col3: '2 years',
                                  col4: 'description ...',
                                  is_regex: true
                              },
                              {
                                  col1: '_gid',
                                  col2: 'google.com',
                                  col3: '1 day',
                                  col4: 'description ...',
                              }
                          ]
                      }, {
                          title: 'Cookies für Werbung und Zielgruppenansprache',
                          description: 'Diese Cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht und welche Links Sie angeklickt haben. Alle Daten sind anonymisiert und können nicht verwendet werden, um Sie zu identifizieren',
                          toggle: {
                              value: 'targeting',
                              enabled: false,
                              readonly: false
                          }
                      }, {
                          title: 'Weitere Informationen',
                          description: 'Bei Fragen zu unserer Politik in Bezug auf Cookies und Ihre Wahlmöglichkeiten wenden Sie sich bitte an <a class="cc-link" href="#yourcontactpage">uns</a>.',
                      }
                  ]
              }
          }
      }
  });
});